import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "../app/store";
import {
    ModerationRequest,
    NewCommentRequest,
    NewPublicationRequest,
    PublicationFilterRequest,
    UserCreateRequest,
    UserUpdateAuthUser,
    UserUpdateRequest
} from "./types-request";
import {
    AuthUser,
    Comment,
    PagablePublicationShort,
    PublicationFull,
    PublicationModeration,
    PublicationShort,
    PublicationWithModeration,
    User
} from "./types-response";

export const API_URL = "https://club264.osc-fr1.scalingo.io";
// export const API_URL = "http://localhost:8080/";

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set("Authorization", 'Bearer ' + token);
        }
        return headers;
    },
});

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);
    // Sign out on 401 Unauthorized.
    if (result.error && result.error.status === 403) {
        //   api.dispatch(signOut());
    }
    return result;
};

export const logUrl = (url: string): string => {
    console.log(`api query ${url} (${new Date().toISOString()})`);
    return url;
};
export const logApiReq = (url: string, params: object): string => {
    console.log(`api query ${url} (${new Date().toISOString()})`, { params });
    return url;
};

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithAuth,
    endpoints: (build) => ({
        // --- publications ---
        getPublicationsQ: build.query<PagablePublicationShort, PublicationFilterRequest>({
            query: (data) => ({
                url: `/publications/all`,
                method: "POST",
                body: data,
            }),
        }),
        getPublicationsM: build.mutation<PagablePublicationShort, PublicationFilterRequest>({
            query: (data) => ({
                url: `/publications/all`,
                method: "POST",
                body: data,
            }),
        }),
        getPublicationsWithModeration: build.query<PublicationWithModeration[], any>({
            query: (_data) => ({
                url: `/publications/withModeration`,
                method: "GET",
            }),
        }),
        getPublication: build.query<PublicationFull, number>({
            query: (id) => ({
                url: `/publications/${id}`,
                method: "GET",
            }),
        }),
        createPublication: build.mutation<any, NewPublicationRequest>({
            query: (data) => ({
                url: `/publications/create`,
                method: "POST",
                body: data,
            }),
        }),
        createComment: build.mutation<Comment, NewCommentRequest>({
            query: (data) => ({
                url: `/comments/create`,
                method: "POST",
                body: data,
            }),
        }),
        // --- users ---
        getAuthUser: build.query<AuthUser, any>({
            query: () => ({
                url: `/users/authUser`,
                method: "GET",
            }),
        }),
        getUsers: build.query<User[], any>({
            query: (data) => ({
                url: `/users/all`,
                method: "GET",
                body: data,
            }),
        }),
        createUser: build.mutation<any, UserCreateRequest>({
            query: (data) => ({
                url: logUrl(`/users/create`),
                method: "POST",
                body: data,
            }),
        }),
        updateUser: build.mutation<any, UserUpdateRequest>({
            query: (data) => ({
                url: `/users/update`,
                method: "POST",
                body: data,
            }),
        }),
        updateAuthUser: build.mutation<any, UserUpdateAuthUser>({
            query: (data) => ({
                url: `/users/updateUserByUser`,
                method: "POST",
                body: data,
            }),
        }),
        changeBlockedUser: build.mutation<any, number>({
            query: (id) => ({
                url: `/users/changeBlocked/${id}`,
                method: "POST",
            }),
        }),
        // --- moderation ---
        getModerationPublications: build.query<PublicationModeration[], any>({
            query: (_data) => ({
                url: `/moderation/all`,
                method: "POST",
            }),
        }),
        getModerationPublication: build.query<PublicationShort, number>({
            query: (id) => ({
                url: `/moderation/${id}`,
                method: "GET",
            }),
        }),
        moderation: build.mutation<any, any>({
            query: ({ id, data }: { id: number, data: ModerationRequest }) => ({
                url: `/moderation/${id}/moderate`,
                method: "POST",
                body: data,
            }),
        }),
    })
});

export const {
    // --- publications ---
    useGetPublicationsQQuery,
    useGetPublicationsMMutation,
    useGetPublicationsWithModerationQuery,
    useGetPublicationQuery,
    useCreatePublicationMutation,
    useCreateCommentMutation,
    // --- users ---
    useGetAuthUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useUpdateAuthUserMutation,
    useChangeBlockedUserMutation,
    // --- moderation ---
    useGetModerationPublicationsQuery,
    useGetModerationPublicationQuery,
    useModerationMutation,
} = apiSlice;
